const FARM_IMAGE_BASE_URL = 'https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/farm/';
const CAR_IMAGE_BASE_URL = 'https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/car/';

export {FARM_IMAGE_BASE_URL}
export {CAR_IMAGE_BASE_URL}

const SKIN_TONES = [
    {value: 'fair', num: '1', img: 'fairskin.png', label: 'Fair Skin'},
    {value: 'golden', num: '2', img: 'goldenskin.png', label: 'Golden Skin'},
    {value: 'tan', num: '3', img: 'tanskin.png', label: 'Tan Skin'},
    {value: 'brown', num: '4', img: 'brownskin.png', label: 'Brown Skin'},
    {value: 'darkbrown', num: '5', img: 'darkbrownskin.png', label: 'Dark Brown Skin'},
];

export {SKIN_TONES}

const HAIR_STYLES = [
    {value: 'longhair', num: '6', img: 'long.png', label: 'Long Hair'},
    {value: 'pigtailshair', num: '7', img: 'pigtails.png', label: 'Pigtails Hair'},
    {value: 'waveyhair', num: '8', img: 'wavey.png', label: 'Wavey Hair'},
    {value: 'hijabhair', num: '9', img: 'hijab.png', label: 'Hijab Hair'},
    {value: 'braidshair', num: '10', img: 'braids.png', label: 'Braids Hair'},
    {value: 'buzzhair', num: '1', img: 'buzz.png', label: 'Buzz Hair'},
    {value: 'spikeyhair', num: '2', img: 'spikey.png', label: 'Spikey Hair'},
    {value: 'spikeyfadehair', num: '3', img: 'spikeyfade.png', label: 'Spikey Fade Hair'},
    {value: 'afrohair', num: '4', img: 'afro.png', label: 'Afro Hair'},
    {value: 'afrofadehair', num: '5', img: 'afrofade.png', label: 'Afro Fade Hair'},
];

export {HAIR_STYLES}


const HAIR_COLOURS = [
    {value: 'black', num: '1', img: 'blackhair.png', label: 'Black Hair'},
    {value: 'darkbrown', num: '2', img: 'darkbrownhair.png', label: 'Dark Brown Hair'},
    {value: 'brown', num: '3', img: 'brownhair.png', label: 'Brown Hair'},
    {value: 'orange', num: '4', img: 'orangehair.png', label: 'Orange Hair'},
    {value: 'blonde', num: '5', img: 'blondehair.png', label: 'Blonde Hair'},
    {value: 'red', num: '6', img: 'redhair.png', label: 'Red Hair'},
];

export {HAIR_COLOURS}