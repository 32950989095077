import Hotjar from '@hotjar/browser';
import { TelemetryService } from './services/TelemetryService';
import ApplicationInsightsProvider from './services/telemetryProviders/ApplicationInsightsProvider';
import ConsoleTelemetryProvider from './services/telemetryProviders/ConsoleTelemetryProvider';
import * as Sentry from "@sentry/react";
import ReactGA from 'react-ga4';
export const setupHotjar = () => {
  const siteId = Number(process.env.REACT_APP_HOTJAR_SITE_ID);
  const hotjarVersion = 6;

  if (!isNaN(siteId)) {
    Hotjar.init(siteId, hotjarVersion);
  }
};

export const setupTelemetry = () => {

  const connectionString = process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING;
  const telemetryProvider = connectionString
    ? new ApplicationInsightsProvider(connectionString)
    : new ConsoleTelemetryProvider();

  const telemetryService = new TelemetryService(telemetryProvider);

  return telemetryService;
};


export const setupSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: process.env.REACT_APP_TRACE_SAMPLE_RATE, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", "https://ourstorytime.azurewebsites.net", "https://ourstorytime.com"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

};


// create function that returns the feedback 
export const createUserFeedbackSentry = () => {
  return Sentry.feedbackIntegration({
    autoInject: false,
    showBranding: false,
    showName: false,
    showEmail: false,
    triggerLabel: "Share your thoughts",
    formTitle: "We value your feedback!",
    submitButtonLabel: "Send Feedback",
    messagePlaceholder: "What can we improve to make your experience better?",
    successMessageText: "Thanks! Your feedback helps us improve.",
    
    themeLight: {
      background: "yellowgreen", /* Light green background */
      foreground: "#1c2c47", /* Dark blue text */
      accentBackground: "#FFD700", /* Bright yellow button */
      accentForeground: "#1c2c47", /* Dark blue text on buttons */
      triggerBackground: "#FFD700", /* Bright yellow trigger button */
      triggerForeground: "#1c2c47", /* Dark blue text for trigger */
    },
    themeDark: {
      background: "yellowgreen", /* Light green background */
      foreground: "#1c2c47", /* Dark blue text */
      accentBackground: "#FFD700", /* Bright yellow button */
      accentForeground: "#1c2c47", /* Dark blue text on buttons */
      triggerBackground: "#FFD700", /* Bright yellow trigger button */
      triggerForeground: "#1c2c47", /* Dark blue text for trigger */
    },
  });
};




export const setupGoogleTagManager = (gtmId) => {
  if (!gtmId) return;

  ReactGA.initialize([
    {
      trackingId: gtmId,
    }
  ]);
};


