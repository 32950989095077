import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import "../style/home.css"
import "../style/main.css"
import { Button } from "reactstrap";
import { UserAuth } from "../components/AuthContext";
import { usePageViewTracker, useEventTracker } from "../services/TelemetryHooks";
import Book from "../components/library/Book";
import LibraryPagination from './SignedIn/components/LibraryPagination';
import ScrollToTop from "../components/ScrollToTop";
import LibraryHeader from "../components/library/LibraryHeader";
import {CAR_IMAGE_BASE_URL, FARM_IMAGE_BASE_URL, HAIR_COLOURS, HAIR_STYLES, SKIN_TONES} from "../utils/BookCoverUtils";
import MotionButton from "../components/buttons/MotionButton";
import {motion, useInView} from "framer-motion";
import {createUserFeedbackSentry} from "../setup";

const SignedIn = () => {
    usePageViewTracker('Signed In');
    const trackEvent = useEventTracker();

    document.body.style.backgroundImage = 'url("https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/navy bg for GBCD.png")';
    const { user, logout } = UserAuth();
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 3;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const rootImageFolder = "https://oststorage.blob.core.windows.net/ostpstrblb/app/website_feature_images/"
    const schoolBookImg = "https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/school_cover.png";
    const birthdayBookImg = "https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/birthday_cover.png";
    const zooBookImg = "https://ostbookstorage.blob.core.windows.net/ostblobstorage/app/app/zoo_cover.png";
    const avatarChangeBtnImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/icons/update_avatar_button.png";
    const hardCoverBtnImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/icons/hardcover_button.png";
    const logoutBtnImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/icons/logout_button.png";
    const readerDifficultyBtnImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/icons/reader_difficulty_button.png";
    const heartImg = rootImageFolder + "print_book_heart.png"
    const printBookCharacterImg = rootImageFolder + "print_book_character.png"    
    
    //covers
    const farmBookCover = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/banyard_brunch_cover.png";
    const carBookCover = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/car_book_cover.png";
    const juneBookCoverImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/june_release.png";
    const julyBookCoverImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/july_release.png";
    const augustBookCoverImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/august_release.png";
    const septemberBookCoverImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/september_release.png";
    const octoberBookCoverImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/october_release.png";
    const novemberBookCoverImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/november_release.png";
    const decemberBookCoverImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/december_release.png";
    const eidBookCoverImg = "https://oststorage.blob.core.windows.net/ostpstrblb/app/book_covers/eid_book_cover.png";


    const [hairColour, setHairColour] = useState("black");
    const [skinTone, setSkinTone] = useState("fair");
    const [hairStyle, setHairStyle] = useState("buzz");
    const [characterName, setCharacterName] = useState("");
    const [promoCode, setPromoCode] = useState("");
    const [zooBookCode, setZooBookCode] = useState("");
    const [carBookCode, setCarBookCode] = useState("");
    const [farmBookCode, setFarmBookCode] = useState("");
    const [testBookCode, setTestBookCode] = useState("");
    const [eidBookCode, setEidBookCode] = useState("");
    const [carBookImgSrc, setCarBookImgSrc] = useState('');
    const [farmBookImgSrc, setFarmBookImgSrc] = useState('');
    const [schoolBookCode, setSchoolBookCode] = useState("");
    const [birthdayBookCode, setBirthdayBookCode] = useState("");

    const ref1 = useRef(null);
    const isInView1 = useInView(ref1);

    const data = {
        hairColour,
        skinTone,
        hairStyle,
        characterName,
        promoCode,
        birthdayBookCode,
        schoolBookCode,
        zooBookCode,
        carBookCode,
        farmBookCode,
        eidBookCode
    };

    useEffect(() => {
        // Create the widget on mount
        const widget = createUserFeedbackSentry().createWidget();
    
        // Append the widget to the DOM
        widget.appendToDom();
    
        // Cleanup function to remove the widget when the component is unmounted
        return () => {
          widget.removeFromDom();
        };
      }, []); 

    const postPurchase = async () => {
        try {
            const url = process.env.REACT_APP_B_KEND + '/osthomepage';

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "email": user.email
                }),
            }).then(response => response.json())
                .then(data => data);

            const data = await response;
            setHairStyle(data.hairStyle);
            setHairColour(data.hairColour);
            setSkinTone(data.skinTone);
            setCharacterName(data.characterName);
            setBirthdayBookCode(response.birthdayBook);
            setZooBookCode(response.zooBook);
            setSchoolBookCode(response.schoolBook);
            setCarBookCode(response.carBook);
            setFarmBookCode(response.farmBook);
            setTestBookCode(response.farmBook);
            setEidBookCode(response.eidBook);
        } catch (error) {
            // Handle error
        }
    };

    useEffect(() => {

        if (user !== undefined && user.email !== undefined) {
            postPurchase();
        }
    }, [user]);

    const handleLogout = async () => {
        try {
            trackEvent('Log Out', {
                email: user.email
            });

            await logout();
            navigate('/');
        } catch (e) {
            console.log(e.message())
        }
    }


    const updateAvatar = () => {
        trackEvent('Update Avatar');
        navigate('/store-character');
    }

    const purchaseHardCover = () => {
        trackEvent('Purchase Hard Cover');
        navigate('/buy-hard-cover-book-form');
    }

    const unlockPass = async () => {
        trackEvent('Unlock Books');
        navigate('/upgrade-plan-form');
    }

    // TODO: 16/09/2024 - Revisit logic for promocode. This is causing a double click to read book, hiderence to reading book. 
    const readBook = async (bookCode) => {
        try {
            if(bookCode == "") {
                return;
            }
            await postPurchase();
            data.promoCode = bookCode;
            navigate("/book-reader", { state: data });
        } catch (error) {
            console.error("Error in readBook:", error);
            // Handle the error, e.g., display an error message to the user
        }
    };

    useEffect(() => {
        const skinToneNum = getNumByValue(SKIN_TONES, skinTone);
        const hairStyleNum = getNumByValue(HAIR_STYLES, hairStyle);
        const hairColourNum = getNumByValue(HAIR_COLOURS, hairColour);

        const avatarImageName = `${hairStyleNum}_${skinToneNum}_${hairColourNum}.png`;
        const farmImagePath = `${FARM_IMAGE_BASE_URL}${avatarImageName}`;
        const carImagePath = `${CAR_IMAGE_BASE_URL}${avatarImageName}`;
        setFarmBookImgSrc(farmImagePath);
        setCarBookImgSrc(carImagePath);

    }, [skinTone, hairStyle, hairColour]);

    const getNumByValue = (list, value) => list.find((item) => item.value === value)?.num || '1';



    const books = [
        {
            image: carBookImgSrc,
            bookCode: carBookCode,
            testBookCode: testBookCode,
            isReleased: true,
            readBookFunc: readBook,
            overlayText: characterName + "'s Favourite Car",
            textColor:"#d52236",
            outlineColor: "black",
        },

        {
            image: farmBookImgSrc,
            bookCode: farmBookCode,
            testBookCode: testBookCode,
            isReleased: true,
            readBookFunc: readBook,
            overlayText: characterName + "'s Barnyard Brunch",
            textColor:"#102E57",
            outlineColor: "yellow",

        },
        {
            image: eidBookCoverImg,
            bookCode: eidBookCode,
            testBookCode: testBookCode,
            isReleased: true,
            readBookFunc: readBook,
            overlayText: "",

        },
        {
            image: birthdayBookImg,
            bookCode: birthdayBookCode,
            testBookCode: testBookCode,
            isReleased: false,
            readBookFunc: readBook,
            overlayText: "",
        },
        {
            image: schoolBookImg,
            bookCode: schoolBookCode,
            testBookCode: testBookCode,
            isReleased: false,
            readBookFunc: readBook,
            overlayText: "",
        },
        {
            image: zooBookImg,
            bookCode: zooBookCode,
            testBookCode: testBookCode,
            isReleased: false,
            readBookFunc: readBook,
            overlayText: "",
        },


        {
            image: julyBookCoverImg,
            bookCode: "",
            testBookCode: testBookCode,
            isReleased: false,
            readBookFunc: readBook,
            overlayText: "",
        },
        {
            image: augustBookCoverImg,
            bookCode: "",
            testBookCode: testBookCode,
            isReleased: false,
            readBookFunc: readBook,
            overlayText: "",
        },
        {
            image: septemberBookCoverImg,
            bookCode: "",
            testBookCode: testBookCode,
            isReleased: false,
            readBookFunc: readBook,
            overlayText: "",
        },
        {
            image: octoberBookCoverImg,
            bookCode: "",
            testBookCode: testBookCode,
            isReleased: false,
            readBookFunc: readBook,
            overlayText: "",
        },
        {
            image: novemberBookCoverImg,
            bookCode: "",
            testBookCode: testBookCode,
            isReleased: false,
            readBookFunc: readBook,
            overlayText: "",
        },
        {
            image: decemberBookCoverImg,
            bookCode: "",
            testBookCode: testBookCode,
            isReleased: false,
            readBookFunc: readBook,
            overlayText: "",
        },
    ].sort((a, b) => Boolean(b.bookCode) - Boolean(a.bookCode));


    return (
        <div className={"col-11 mx-auto "}>
            <ScrollToTop />
            
            {/*Library*/}
            <div className={"col-12 home-box pb-3"}>
                <LibraryHeader />
                
                {/*Books*/}
                <div className="book-container">
                    {books.slice(startIndex, endIndex).map((book, index) => (
                        <Book
                            key={index}
                            image={book.image}
                            bookCode={book.bookCode}
                            testBookCode={book.testBookCode}
                            isReleased={book.isReleased}
                            readBookFunc={book.readBookFunc}
                            overlayText={book.overlayText}
                            textColor={book.textColor}
                            outlineColor={book.outlineColor}
                        />
                    ))}
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <LibraryPagination
                        books={books}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
            </div>

            {/*Print Section*/}
            <div className={"col-12 mt-5 print-container text-center mx-auto small-rounded"}>
                <div className={"col-10 mx-auto"}>
                    <h1 className="text-navy-blue poetsen-font text-center mx-auto">
                        A Special Gift For <br/> Special Moments
                    </h1>
                    <p className={"mt-3"}>
                        Have your personalised story <br/>
                        <strong> professionally printed </strong>
                    </p>
                </div>

                <motion.div
                    className={"text-center mx-auto"}
                    ref={ref1}
                    initial={{opacity: 0, y: 50}}
                    animate={{opacity: isInView1 ? 1 : 0, y: isInView1 ? 0 : 50}}
                    transition={{duration: 0.8}}
                >
                    <img src={heartImg}
                         className={"col-10"}
                    />
                </motion.div>
                
                <div>
                    <img src={printBookCharacterImg}
                         className={"col-5"}
                    />
                </div>
            </div>

            <div className={"col-7 col-sm-5 col-md-3 mt-3 mb-3 mx-auto"}>
                <Link
                    to={"/buy-hard-cover-book-form"}>
                    <MotionButton
                        buttonText={"REGISTER INTEREST"}
                        buttonClass={"secondary-button"}
                    />
                </Link>
            </div>
        
        </div>
    );
};

export default SignedIn;
