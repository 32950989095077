import {React, useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import "../style/home.css"
import "../style/main.css"
import {usePageViewTracker, useEventTracker} from "../services/TelemetryHooks";
import ScrollToTop from "../components/ScrollToTop";
import {motion, useInView} from "framer-motion";
import MotionButton from "../components/buttons/MotionButton";
import {USER_REVIEWS} from "../utils/ReviewDescriptionsUtils";


const Home = () => {
    usePageViewTracker('Home');
    const trackEvent = useEventTracker();
    const rootImageFolder = "https://oststorage.blob.core.windows.net/ostpstrblb/app/website_feature_images/"
    const howItWorksOneImg = rootImageFolder + "why_square_1.png"
    const howItWorksTwoImg = [rootImageFolder + "why_square_2_1.png", rootImageFolder + "why_square_2_2.png", rootImageFolder + "why_square_2_3.png"];
    const howItWorksThreeImg = rootImageFolder + "why_square_3.png"
    const heartImg = rootImageFolder + "print_book_heart.png"
    const printBookCharacterImg = rootImageFolder + "print_book_character.png"
    const howItWorksStepOneImg = [rootImageFolder + "how_square_1_1.png", rootImageFolder + "how_square_1_2.png", rootImageFolder + "how_square_1_3.png"];
    const howItWorksStepTwoImg = [rootImageFolder + "how_square_2_1.png", rootImageFolder + "how_square_2_2.png", rootImageFolder + "how_square_2_3.png"];
    const howItWorksStepThreeImage = [rootImageFolder + "how_square_3_1.png", rootImageFolder + "how_square_3_2.png", rootImageFolder + "how_square_3_3.png"];
    const ipadPromoImage = [rootImageFolder + "iPad_hero_1.png", rootImageFolder + "iPad_hero_2.png", rootImageFolder + "iPad_hero_3.png", rootImageFolder + "iPad_hero_4.png", rootImageFolder + "iPad_hero_5.png", rootImageFolder + "iPad_hero_6.png"]
    const characterPromoImage = [rootImageFolder + "character_hero_1.png", rootImageFolder + "character_hero_2.png", rootImageFolder + "character_hero_3.png", rootImageFolder + "character_hero_4.png", rootImageFolder + "character_hero_5.png", rootImageFolder + "character_hero_6.png"]
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 767);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const ref7 = useRef(null);
    const ref8 = useRef(null);
    const isInView1 = useInView(ref1);
    const isInView2 = useInView(ref2);
    const isInView3 = useInView(ref3);
    const isInView4 = useInView(ref4);
    const isInView5 = useInView(ref5);
    const isInView6 = useInView(ref6);
    const isInView7 = useInView(ref7);
    const isInView8 = useInView(ref8);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % howItWorksTwoImg.length);
    };

    const [currentIpadImageIndex, setCurrentIpadImageIndex] = useState(0);
    const nextIpadImage = () => {
        setCurrentIpadImageIndex((prevIndex) => (prevIndex + 1) % ipadPromoImage.length);
    };

    const handleClick = (eventName) => {
        trackEvent(eventName);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            nextImage();
            nextIpadImage();
        }, 1200);

        return () => clearInterval(intervalId); // Clean up interval on component unmount
    }, []);


    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 992); // Bootstrap 'lg' is 992px and above
        };

        // Attach the event listener for resize
        window.addEventListener("resize", handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className={"form-colour"}>
            <ScrollToTop/>

            {/*Section - Main Landing*/}
            <div className="col-12 landing-container text-center mx-auto ">
                <div className={"col-11 landing-main-text mx-auto"}>
                    <h1 className="poetsen-font text-center mx-auto">
                        {/*We want the text to be 1 line on big screens and 2 on small*/}
                        Personalised {!isLargeScreen && <br/>} Bedtime Stories
                    </h1>
                </div>
                <div>
                    <img
                        className={"mt-2 col-7 col-md-4 col-lg-3 "}
                        src={ipadPromoImage[currentIpadImageIndex]}
                        alt={"create account graphic"}
                    />
                </div>

                <div className={"col-7 col-sm-5 col-md-3 mt-3 mb-3 mx-auto"}>
                    <Link
                        to={"/checkout-trial"}>
                        <MotionButton
                            buttonText={"CREATE A FREE STORY"}
                            // onClick={() => handleClick('Show Now')}
                            buttonClass={"secondary-button"}/>
                    </Link>
                </div>
                <div className={""}>
                    <img
                        className={"col-7 col-md-4 col-lg-2 mx-auto text-center"}
                        src={characterPromoImage[currentIpadImageIndex]}
                        alt={"create account graphic"}
                    />
                </div>
            </div>

            {/*Text Banner - How Does it Work*/
            }
            <div className="feature-div-container banner-text ">
                <h3 className={"text-white"}>How It Works?</h3>
            </div>


            {/*Section - How It Works Guide*/
            }
            <div className="multi-item-container">
                <div className={"row col-12 mx-auto text-center mb-5 mt-2"}>
                    
                    {/* Image 1 */}
                    <motion.div
                        className={"col-xl-3 col-11 mx-auto mt-5"}
                        ref={ref1}
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: isInView1 ? 1 : 0, y: isInView1 ? 0 : 50}}
                        transition={{duration: 0.8}}
                    >
                        <img
                            className={"full-width-height-image"}
                            src={howItWorksStepOneImg[currentImageIndex]}
                            alt={"create hardcover book graphic"}
                        />
                    </motion.div>

                    {/* Image 2 */}
                    <motion.div
                        className={"col-xl-3 col-11 mx-auto mt-5"}
                        ref={ref2}
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: isInView2 ? 1 : 0, y: isInView2 ? 0 : 50}}
                        transition={{duration: 0.8}}
                    >
                        <img
                            className={"full-width-height-image"}
                            src={howItWorksStepTwoImg[currentImageIndex]}
                            alt={"create hardcover book graphic"}
                        />
                    </motion.div>

                    {/* Image 3 */}
                    <motion.div
                        className={"col-xl-3 col-11 mx-auto mt-5"}
                        ref={ref3}
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: isInView3 ? 1 : 0, y: isInView3 ? 0 : 50}}
                        transition={{duration: 0.8}}
                    >
                        <img
                            className={"full-width-height-image"}
                            src={howItWorksStepThreeImage[currentImageIndex]}
                            alt={"create hardcover book graphic"}
                        />
                    </motion.div>

                    <div className={"col-8 col-md-4 mt-5 mb-3 mx-auto text-center mb-5"}>
                        <Link to={"/checkout-trial"}
                        >
                            <MotionButton
                                buttonText={"CREATE A FREE STORY"}
                                buttonClass={"secondary-button"}
                                onClick={() => handleClick('Show Now')}
                            />
                        </Link>
                    </div>
                </div>
            </div>

            {/*Text Banner - Our Range Of Stories*/}
            <div className={"feature-div-container banner-text "}>
                <h3 className={"text-white"}>Print Your Personalised Story</h3>
            </div>

            {/*Print Section*/}
            <div className={"col-12 print-container text-center mx-auto"}>
                <div className={"col-10 mx-auto"}>
                    <h1 className="text-navy-blue poetsen-font text-center mx-auto">
                        A Special Gift For <br/> Special Moments
                    </h1>
                    <p className={"mt-3"}>
                        Have your personalised story <br/>
                        <strong> professionally printed </strong>
                    </p>
                </div>

                <motion.div
                    className={"text-center mx-auto"}
                    ref={ref7}
                    initial={{opacity: 0, y: 50}}
                    animate={{opacity: isInView7 ? 1 : 0, y: isInView7 ? 0 : 50}}
                    transition={{duration: 0.8}}
                >
                    <img src={heartImg}
                         className={"col-10"}
                    />

                </motion.div>

                <div>
                    <img src={printBookCharacterImg}
                         className={"col-5"}
                    />
                </div>

            </div>


            {/*Text Banner - Our Range Of Stories*/}
            <div className="feature-div-container banner-text ">
                <h3 className={"text-white"}>Reviews</h3>
            </div>

            {/*Product Reviews*/}
            <div className={"infinite-reviews-container pb-5"}
                 style={{overflow: 'hidden', padding: '20px', position: 'relative'}}>
                <h1 className={"col-10 poetsen-font text-center mx-auto text-white"}> 4/5 Parents</h1>
                <h5 className={"col-11 poetsen-font text-center mx-auto text-white"}> Would Recommend Us To A
                    Friend*</h5>

                <div
                    className={"reviews-wrapper mb-5"}
                    style={{
                        display: 'flex',
                        gap: '20px', // Space between items
                        padding: '10px', // Extra padding around the container
                    }}
                >

                    {[...USER_REVIEWS, ...USER_REVIEWS, ...USER_REVIEWS, ...USER_REVIEWS].map((review, index) => (
                        <div className={"bg-white mt-3"}
                             key={index}
                             style={{
                                 width: '250px',
                                 height: '300px',
                                 flexShrink: 0,
                                 backgroundColor: '#f5f5f5',
                                 borderRadius: '10px',
                                 padding: '20px',
                                 display: 'flex',
                                 flexDirection: 'column',
                                 alignItems: 'center',
                                 // justifyContent: 'center',
                                 // border: '5px solid #091E3B',  // Add a navy border with 2px width

                             }}
                        >
                            <img
                                src={review.image}
                                alt="Reviewer"
                                style={{width: '100px', height: '100px', borderRadius: '10%', marginBottom: '10px'}}
                            />
                            {/*<div>{'⭐'.repeat(review.rating)}</div>*/}
                            <p className={"text-center col-10 "}>{review.text}</p>
                            <p className={"agbalumo-font"}>{review.name}</p>
                        </div>

                    ))}

                </div>
            </div>


            {/*Text Banner - How Does it Work*/
            }
            <div className="feature-div-container banner-text ">
                <h3 className={"text-white"}>More Than A Story</h3>
            </div>


            {/*Section - How It Works Guide*/
            }
            <div className="multi-item-container">
                <div className={"row col-12 mx-auto text-center mb-5"}>


                    {/* Image 1 */}
                    <motion.div
                        className={"col-xl-3 col-11 mx-auto mt-5"}
                        ref={ref4}
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: isInView4 ? 1 : 0, y: isInView4 ? 0 : 50}}
                        transition={{duration: 0.8}}
                    >
                        <img
                            className={"full-width-height-image"}
                            src={howItWorksOneImg}
                            alt={"create account graphic"}
                        />
                    </motion.div>

                    {/* Image 2 */}
                    <motion.div
                        className={"col-xl-3 col-11 mx-auto mt-5"}
                        ref={ref5}
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: isInView5 ? 1 : 0, y: isInView5 ? 0 : 50}}
                        transition={{duration: 0.8}}
                    >
                        <img
                            className={"full-width-height-image"}
                            src={howItWorksTwoImg[currentImageIndex]}
                            alt={"create hardcover book graphic"}
                        />
                    </motion.div>

                    {/* Image 3 */}
                    <motion.div
                        className={"col-xl-3 col-11 mx-auto mt-5"}
                        ref={ref6}
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: isInView6 ? 1 : 0, y: isInView6 ? 0 : 50}}
                        transition={{duration: 0.8}}
                    >
                        <img
                            className={"full-width-height-image"}
                            src={howItWorksThreeImg}
                            alt={"generate personal library graphic"}
                        />
                    </motion.div>

                    <div className={"col-8 col-md-4 mt-5 mb-3 mx-auto text-center "}>
                        <Link to={"/checkout-trial"}
                        >
                            <MotionButton
                                buttonText={"CREATE A FREE STORY"}
                                buttonClass={"secondary-button"}
                                onClick={() => handleClick('Show Now')}
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Home;
