import React from 'react';
import PropTypes from 'prop-types';

const Button = ({className, onClick, children}) => (
    <button className={`book-button ${className}`} onClick={onClick}>
        {children}
    </button>
);

Button.propTypes = {
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    children: PropTypes.node.isRequired,
};

Button.defaultProps = {
    onClick: null,
};


const Book = ({
                  image,
                  bookCode,
                  isReleased = false,
                  testBookCode,
                  readBookFunc,
                  unlockBookFunc,
                  refreshFunc,
                  overlayText,
                  textColor = "Ranch0",
                  outlineColor = "white",
    
    
    
              }) => {

    const words = overlayText.split(' ').map((word, index) => (
        <span key={index}>
      {word}
            <br/>
    </span>
    ));

    const coverStyle = {
        color: textColor,
        outline: `${outlineColor}`,
    };

    const renderButton = () => {
        if (!isReleased) {
            return <Button className="comingsoon-item-button">COMING SOON</Button>;
        }
        if (bookCode) {
            return (
                <Button
                    className="read-item-button"
                    onClick={() => readBookFunc(bookCode)}
                >
                    READ
                </Button>
            );
        }
        if (!testBookCode) {
            return (
                <Button className="refresh-item-button" onClick={refreshFunc}>
                    REFRESH
                </Button>
            );
        }
        return (
            <Button className="sale-item-button" onClick={unlockBookFunc}>
                UNLOCK
            </Button>
        );
    };

    return (
        <div className="book-item login-book-read">
            <div className="book-cover-image-container home-read-button">
                <img className="login-book book-cover"
                     style={{filter: !isReleased ? 'grayscale(100%)' : 'none'}}
                     onClick={() => readBookFunc(bookCode)} src={image} alt="Book cover"/>
                {overlayText.length > 2 && (
                    <div style={coverStyle} className="book-cover-overlay-text">{words}</div>
                )}
            </div>
            <div className="mx-auto text-center">{renderButton()}</div>
        </div>
    );
};

Book.propTypes = {
    image: PropTypes.string.isRequired,
    bookCode: PropTypes.string,
    isReleased: PropTypes.bool,
    testBookCode: PropTypes.string,
    readBookFunc: PropTypes.func.isRequired,
    unlockBookFunc: PropTypes.func,
    refreshFunc: PropTypes.func,
};

Book.defaultProps = {
    bookCode: '',
    isReleased: false,
    testBookCode: '',
    unlockBookFunc: () => {
    },
    refreshFunc: () => {
    },
};

export default Book;
